<template>
  <v-container
    fluid
    style="height: 100%; margin:0px; padding:0px; min-height: inherit"
    class="d-flex flex-row"
  >
    <agency-profile-left-section class="hidden-sm-and-down" />
    <agency-profile-feed-section />
  </v-container>
</template>
<script>
import AgencyProfileFeedSection from "./AgencyProfileFeedSection.vue";
import AgencyProfileLeftSection from "./AgencyProfileLeftSection.vue";
export default {
  components: { AgencyProfileLeftSection, AgencyProfileFeedSection },
};
</script>
