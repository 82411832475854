<template>
  <div
    class="feed-body"
    :style="
      $vuetify.breakpoint.smAndDown
        ? 'margin-left: 20px; margin-right: 20px'
        : 'margin-left: 30px;'
    "
  >
    <v-img
      src="@/assets/loading.gif"
      width="200px"
      contain
      class="mb-10"
      v-show="!loadingMore && (loading || loadCompleted < events.length)"
    />
    <div
      v-show="loadingMore || (!loading && loadCompleted >= events.length)"
      class="feed-contents"
    >
      <create-event
        style="width: 100%"
        v-if="profile._id == $route.params.id"
      />
      <div style="height: 20px;" v-else />
      <feed-event-card
        v-for="(event, index) in events"
        :key="event._id"
        :_id="event._id"
        :eventIndex="events.length - index"
        :loadedData="loadedData"
        :loadMoreEvents="loadMoreEvents"
      />
      <div
        v-if="!loading && !loadingMore && !events.length"
        class="d-flex flex-column align-center justify-center"
        style="height: 250px"
      >
        <div class="mb-3">
          <v-img
            :src="require('@/assets/svg/empty-post.svg')"
            style="width: 60px; height: 50px"
            contain
          />
        </div>
        No events post
      </div>
      <v-progress-circular indeterminate color="amber" v-if="loadingMore" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import CreateEvent from "../../event_feeds/CreateEvent.vue";
import FeedEventCard from "../../event_feeds/FeedEventCard.vue";

export default {
  components: { CreateEvent, FeedEventCard },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
    };
  },
  methods: {
    ...mapActions("event", {
      fetchUserEvents: "fetchUserEvents",
      loadMoreAgency: "loadMoreUserEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadedData() {
      this.loadCompleted++;
    },
    loadMoreEvents() {
      this.loadingMore = true;
      this.loadMoreAgency({
        _id: this.$route.params.id,
        skip: this.events.length,
      })
        .then(() => {
          console.log("load more done");
          setTimeout(() => {
            this.loadingMore = false;
            console.log("load more set false");
          }, 2500);
        })
        .catch((error) => {
          this.loadingMore = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
  },
  computed: {
    ...mapGetters({
      events: "event/getUserEvents",
      profile: "auth/getProfile",
    }),
  },
  watch: {
    "$route.params.id": function(newValue) {
      this.loading = true;
      this.loadCompleted = 0;
      this.fetchUserEvents(newValue)
        .then(() => {
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchUserEvents(this.$route.params.id)
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  max-width: 650px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
}
</style>
